

























































import Vue from 'vue';
import { mapGetters } from 'vuex';

interface CData {
  newPassword: string;
  confirmPassword: string;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
}

interface CMethods {
  changePassword: () => void;
}

interface CComputed {
  isValid: boolean;
  isLoggedin: boolean;
}

export default Vue.extend<CData, CMethods, CComputed>({
  name: 'change-password',
  data: () => ({
    newPassword: '',
    confirmPassword: '',
    showNewPassword: false,
    showConfirmPassword: false,
  }),
  methods: {
    async changePassword() {
      if (await this.$store.dispatch('changePassword', this.newPassword)) {
        this.$router.back();
      }
    },
  },
  computed: {
    ...mapGetters(['isLoggedin']),
    isValid() {
      return this.newPassword.trim().length !== 0 && this.newPassword === this.confirmPassword;
    },
  },
  mounted() {
    if (!this.isLoggedin) {
      this.$router.push('/');
    }
  },
});
